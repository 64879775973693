import React from "react";
import styles from "styles/footer.module.css";

type Props = {};

function CryptoFooter({}: Props) {
  return (
    <>
      <div className={styles.space} />
      <div className={styles.footer}>
        <p>
          CryptoSports Pty Ltd. All Rights Reserved, 2024.{" "}
          <a href="/privacy" target="_blank" rel="noreferrer">Privacy Policy.</a>{" "}
          <a href="/terms" target="_blank" rel="noreferrer">Terms of Service.</a>
        </p>
      </div>
    </>
  );
}

export default CryptoFooter;
